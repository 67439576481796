<template>
  <div class="patent">
    <div class="patent-wrap" :class="{ imageRight: imageRight, [$route.params.slug]: true }">
      <div class="patent-img" :class="[$route.params.slug]">
        <img :src="info.file ? info.file : info.image" alt="patent-file" />
      </div>
      <div class="patent-text">
        <div class="patent-text__title">{{ info.title }}</div>
        <div class="patent-text__description" :class="[$route.params.slug]" v-html="info.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "patent",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    imageRight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.imageRight {
  flex-direction: row-reverse;
}

.patent {
  &-wrap {
    overflow-y: hidden;
    gap: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.o-kompanii {
      gap: 30px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &-img {
    width: 50%;
    height: 430px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.o-kompanii {
      width: 50%;
      height: 430px;
      margin-top: 73px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;

    &__title {
      font-weight: normal;
      font-size: 40px;
      line-height: 49px;
      text-transform: uppercase;
      color: #000000;
    }

    &__description {
      margin-top: 20px;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #4f4f4f;

      &.o-kompanii {
        margin-top: 0;

        ul {
          margin-top: -12px;
          margin-bottom: -12px;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          list-style: disc !important;
        }
      }

      ul {
        list-style: disc !important;
      }

      ol {
        list-style: decimal;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .patent-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0 0 15px;
  }
  .imageRight {
    .patent-text {
      padding: 30px 15px 0 0;
    }
  }

  .patent-text__title {
    font-size: 30px;
    line-height: 39px;
  }
  .patent-text__description {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 750px) {
  .patent-img {
    height: 400px;
  }

  .patent-text__title {
    font-size: 24px;
    line-height: 26px;
  }
  .patent-text__description {
    font-size: 16px;
    line-height: 18px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 650px) {
  .patent-img.o-kompanii {
    margin-top: 0;
    width: 100%;
  }

  .patent-text {
    height: 100% !important;
  }

  .patent-wrap {
    flex-direction: column;
  }
  .patent-text {
    margin-left: 0;
    width: 100%;
    height: auto;
  }
  .patent-img {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .patent-img {
    height: 220px !important;
    max-width: 500px;
    margin-top: 0;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .patent-text {
    //height: 400px;
    margin-left: 0;
    padding: 15px;
  }
}
</style>
