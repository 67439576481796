import { mapGetters } from "vuex";
import pageWrap from "../page-wrap/index.vue";

export default {
  name: "page-main",
  components: {
    pageWrap,
  },
  computed: {
    ...mapGetters({
      page: "pages/page",
      pagesLoading: "pages/pagesLoading",
      headerMenu: "setting/headerMenu",
    }),
  },
};
